































































































































































































































































































































.ilikemerchandisetextow{
  width: 100%;
height: 14px !important;
font-size: 10px;
font-family: PingFang SC;
font-weight: 500;
padding-left:9px ;
color: #999999;
}

.ilikemerchandisetexton{
  width: 160px !important;
height: 20px !important;
font-size: 14px;
font-family: PingFang SC;
font-weight: bold;
color: #2E3237;
padding-left:9px ;
margin-bottom: 5px !important;
display: -webkit-box;
line-height: 20px;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ilikemerchandisetext{
  width: 100% !important;
  height: 54px !important;
overflow: hidden;
}
*{padding: 0;margin: 0;}
.ilikemerchandise div img {
  width: 100%;
  height: 125px;
  border-radius: 8px;
}
.ilikemerchandise div{
  width: 168px;
  height: 181px;
  float: left;
  
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 10px;
}
.Ilike{
  height: 22px;
font-size: 16px;
font-family: PingFang SC;
font-weight: bold;
line-height: 22px;
color: #2E3237;
padding: 12px;
}
.navigateBack{
  width: 10px;
  position: absolute;
  z-index: 999;
  top: 20px;
  left: 10px;
}
.businesshours img{
  width:12px ;
  height: 12px;
  padding: 0 4px 0 12px ;
  float: left;

}
.businessTime{
  float: left;
}
.businesshours{
  width: 100%;
  height: 17px;
  float: left;
  font-size: 12px;
font-family: PingFang SC;
font-weight: 500;
margin-top: 12px;
color: #2E3237;
display: flex;
justify-content: left;
align-items: center;
}
.CategoryList div img{
  width: 75px;
  height: 100%;
  border-radius: 4px;
}
.CategoryList div{
  float: left;
  height: 68px;
  margin: 12px 3px  0 2.5px ;
}
.CategoryList{
  width: 325px;
  margin-left: 12px;
}
.phone{
  width: 22px;
  height: 22px;
  float: right;
  margin-right: 8px;
}
.Shopfordetailsview p {
  width: 235px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2E3237;
  float: left;
}
.Shopfordetailsviewion{
  width: 11px;
  height: 13.5px;
  margin: 0 5px 0 12px;
  float: left;
  }
.Shopfordetailsview{
  width: 100%;
  margin-top: 12px;
overflow: hidden;
  
}
.Shopfordetails{
  width: 351px;
background: rgba(255,255,255,1);
opacity: 1;
border-radius: 4px;
margin:8px 12px;
padding-bottom:12px ;
overflow: hidden;
}
.HZL_cat_img{
  width: 44px;
  height: 44px;

}
.goodsPricer{
  position: absolute;
  top: 9px ;
  left:66px ;
  font-size: 22px;
font-family: PingFang SC;
font-weight: bold;
color: #000000;
}
.HZL_cat_img_view{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 3px;
  left: 12px;

}
.HZL_cat_textc{
  background: rgba(153,153,153,1);
  font-size: 14px;
  color: white;
  padding: 6px 28px;
  border-radius:90px ;
position: absolute;
top: 8px;
right: 12px;
}
.HZL_cat{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 375px;
  height: 58px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 99999;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3) 

}
.goodsPricetext{
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #F5652A;
  float: left;
}
.scribePrice{

font-size: 11px;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
opacity: 1;
float: left;
margin:5px 0 0 2px;
text-decoration: line-through;
}
.goodsPrice{
  width: 183px;
  height: 30px;
  position: absolute;
  bottom: 5px;
  right: 12px;
}
.monthSaless{
  font-size: 11px;
font-family: PingFang SC;
font-weight: 400;
color: #999999;
}
.goods{
  width: 183px;
  
font-size: 15px;
font-family: PingFang SC;
font-weight: bold;
color: #000000;
float: left;
margin-left: 4px;

}
.scrollimage img{
  width: 96px;
  height: 96px;
  border-radius: 6px;
 
}
.scrollimage{
  width: 96px;
  height: 96px;
  float: left;
  
}
.manySpecification{
  background-color:#FF5F31 ;
  padding:5px 12px;
  border-radius: 90px;
  font-size: 12px;
font-family: PingFang SC;
font-weight: 500;
float:right;
color: #FFFFFF;
position: absolute;
top: 0;
right: 0;
}
.scroll{
  width: 304rpx;
  height: 108rpx;
  position: relative;
  margin-top: 12px;
  overflow: hidden;
}
.title{
  font-size: 14px;
  font-weight: bold;
}
.scrollview{
  
  width: 304px;
  float: right;
  margin:0 0px 12px 6px;
  background-color: #ffffff;
  overflow: hidden;
 
}
.classify{
  width: 100%;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #FF5F31;
}
#Change{
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}
.page-body{
  width: 100%;
  display: flex;
  flex-direction:column;
  background-color: #ffffff;
  position: absolute;
 top:199px;
  z-index: 9;
}
.light div{
  margin-right: 16px;
  float: left;
  display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.light img{
  width: 12px;
  height: 2px;
  padding-top:2px ;
}
.light{
  margin-top: 0rpx;
  font-size: 14px;
font-family: PingFang SC;
font-weight: 400;
padding-left: 12px;
color: #999999;
background: #ffffff;
padding-bottom:12px ;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background: rgba(249,249,249,1);
}

.el-carousel__item:nth-child(2n+1) {
   background-color: #fff;
}
.monthSales{
  width: 339px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
float: left;
  color: #999999;
  margin: 2px 0 0 12px;
}
.businessheder img{
  width: 44px;
  height: 44px;
}
.busineslogo{
  float: right;
  
  margin: 12px 12px 0 0 ;
  }
.businessName{
  width: 254px;
  font-size: 16px;
font-family: PingFang SC;
font-weight: bold;
float: left;
margin: 12px 0 0 12px;
color: #000000;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
}
.ilikemerchandise{
  width: 351px;
 overflow: hidden;
  margin-top: 8px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
  .businessheder{
    width: 351px;
    height: 87px;
    background: rgba(255,255,255,1);
    box-shadow: 0px 2px 6px rgba(224,224,224,0.1600);
    opacity: 1;
    border-radius: 4px;
    position: absolute;
    top: 100px;
    left: 12px;
    z-index: 999;
  
}
.heder{
  width: 100%;
  height: 142px;
  position: relative;
  overflow: hidden;
}
.heder img{
  width: 100%;
  position: absolute;
  bottom: -35px;

}
#el-dialog__wrapper{
  cursor:pointer;
  display: flex;
  justify-content: center;
}
.mdr5 ::v-deep .el-upload-list{
  display: none;
}
#container{
        padding:0px;
        margin: 0px;
        width: 100%;
        height: 600px;
    }
.mr15 {
  margin-right: 18px;
}
.div{
  width: 437px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* 兼容Safari */
  -webkit-user-select: none; 
  /* 兼容IE 10+ 和 Edge */
  -ms-user-select: none;
  /* 
      ⏹禁止用户选中的标准语法
  */
  user-select: none; 

 
}
.centerdiv{

  display: flex;
  justify-content: center;
  
}
.center{
  position: relative;
  width: 375px;
  height: 667px;
  background-color: #fff;
overflow: hidden;

}
.index{
  width: 375px;
  height: 667px;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y:scroll;

}
#Shopfor{
  left: 0px !important;
}

.centerBack{
  width: 375px;
  height: 667px;
  position: absolute;
  top: 0;
  left: 375px;
  z-index: 999;
  overflow-y:scroll;
  transition: .5s;
  background: #fff;
}
*::-webkit-scrollbar {
	display: none;
}

* {
	-ms-overflow-style: none;
}

* {
	scrollbar-width: none;
}
.centerBack::-webkit-scrollbar { width: 0 !important }

.centerBack { -ms-overflow-style: none; }

.centerBack { overflow: -moz-scrollbars-none; }
.centerBack::-webkit-scrollbar {
  display: none;
}
.index::-webkit-scrollbar {
  display: none;
}
.center::-webkit-scrollbar {
  display: none;
}
