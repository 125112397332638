













 video {
   width: 100%;
   height: 100%;
   object-fit: cover;
     
 }
 .el-carousel ::v-deep  .is-active {
   .el-carousel__button{
       width: 5px;
       height: 5px;
   
       background: #FF5F31;
       border-radius: 50%;
       opacity: 0.5;
   }

 }  
.el-carousel ::v-deep .el-carousel__button{
   width: 5px;
   height: 5px;
   background: #ffffff;
   border-radius: 50%;
   opacity: 1;
 }
   .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
   }
   
   .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
   }
 